import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { CustomSelectWrapper, InputActions, CustomFormItem } from '../../components';
import { InputModelWithSource } from '../../models';
import { InputWithMeta } from '../../componentTypes';

// Please note that this is a generic component if behavior for your input is different - create a new independent component (like BusinessTypeInput.tsx etc.)

interface Props {
    label: string;
    input: InputModelWithSource;
    allowClear?: boolean;
    onChange?: () => void
}

const GenericSelect: InputWithMeta<Props> = ({ label, input, allowClear = true, onChange }) => {
    return (
        <CustomFormItem name={input.guid} required={input.required}>
            <CustomSelectWrapper
                input={input}
                label={label}
                options={input.sourceProvider.options}
                required={input.required}
                topRightNode={<InputActions input={input} />}
                allowClear={allowClear}
                onChange={onChange}
            />
        </CustomFormItem>
    );
};

GenericSelect.meta = {
    inputType: InputModelWithSource.name
};

export default observer(GenericSelect);
