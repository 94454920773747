import * as React from 'react';
import { Form, FormInstance } from 'antd';
import { InputBaseModel, TabBaseModel } from '../models';
import { TypeUtils } from '../../custom_shared/misc';
import { InstanceUtils, Utils } from '../misc';
import { TabFormValues } from '../types';
import { useRequiredConditions } from '.';

export default (tab: TabBaseModel, addFormRef: (id: string, form: FormInstance) => void) => {
    const [formKey, setFormKey] = React.useState(0);

    const [form] = Form.useForm<TabFormValues>();

    const { handleRequiredConditions } = useRequiredConditions(tab);

    const onValuesChange = (changedValues: TabFormValues) => {
        Object.keys(changedValues).forEach(inputGuid => handleInputValuesChange(inputGuid));

        if (!tab.hasError) {
            return;
        }

        const allInputsValid = tab.inputs.filter(i => i.required).every(i => i.hasValue);

        if (allInputsValid) {
            tab.setHasError(false);
        }
    };

    const getFormFieldDateValue = (value: unknown) => {
        if (TypeUtils.isMoment(value)) {
            return value;
        }

        return TypeUtils.isDate(value) ? Utils.formatDateString(value) : null;
    };

    const setFormFieldValue = React.useCallback(
        (input: InputBaseModel) => {
            form.setFieldsValue({
                [input.guid]: input.dataType === 'DateTime' ? getFormFieldDateValue(input.value) : input.value
            });

            handleRequiredConditions(input);
        },
        [form, handleRequiredConditions]
    );

    const resetInputValue = (inputGuid: string) => {
        const input = tab.getInputByGuid(inputGuid);

        if (!input) {
            return;
        }

        const value = TypeUtils.isStringArray(form.getFieldValue(inputGuid)) ? [] : null;

        input.setValue(value);
    };

    const updateChildInputValues = (input: InputBaseModel) => {
        if (!InstanceUtils.isInputWithSource(input.childInput)) {
            return;
        }

        resetInputValue(input.childInput.guid);

        input.childInput.sourceProvider.getSourceOptionsByParent(form.getFieldValue(input.guid));

        updateChildInputValues(input.childInput);
    };

    const handleInputValuesChange = (inputGuid: string) => {
        const input = tab.getInputByGuid(inputGuid);

        if (!input) {
            return;
        }

        const value = form.getFieldValue(inputGuid);

        input.setValue(value);

        updateChildInputValues(input);
    };

    React.useEffect(() => {
        addFormRef(tab.id, form);
    }, [tab, form, addFormRef]);

    React.useEffect(() => {
        setFormKey(prevKey => prevKey + 1);
    }, [tab]);

    React.useEffect(() => {
        const subject = tab.inputValueChangeSubject.subscribe(setFormFieldValue);
        return () => subject.unsubscribe();
    }, [form, tab, setFormFieldValue]);

    React.useEffect(() => {
        tab.inputs.forEach(setFormFieldValue);
    }, [tab.inputs, setFormFieldValue]);

    return {
        form,
        formKey,
        onValuesChange
    };
};
