import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { InputBaseModel } from '../../models';
import { InputRenderer } from '../../components';
import { TabProps } from '../../componentTypes';
import { CountriesInput, CountryGroupsInput } from '../../tab_inputs/CountriesTabInputs';
import { LayoutRenderer } from '../../layout_renderers';
import { Utils } from '../../misc';
import { useTabForm } from '../../hooks';

const InputIds = {
    Countries: 'Countries',
    CountryGroups: 'CountryGroups'
} as const;

const InputComponents = {
    [InputIds.Countries]: CountriesInput,
    [InputIds.CountryGroups]: CountryGroupsInput
} as const;

const CountriesTabRenderer: React.FC<TabProps> = ({ tab, addFormRef }: TabProps) => {
    const { form, formKey, onValuesChange } = useTabForm(tab, addFormRef);

    const getComponentRows = React.useCallback(
        (inputs: InputBaseModel[]) =>
            Utils.groupArray(inputs, 4).map(inputGroup =>
                inputGroup.map(input => (
                    <InputRenderer key={input.id} input={input} component={InputComponents[input.id]} />
                ))
            ),
        []
    );

    return (
        <LayoutRenderer
            tab={tab}
            form={form}
            formKey={formKey}
            onValuesChange={onValuesChange}
            getComponentRows={getComponentRows}
        />
    );
};

export default observer(CountriesTabRenderer);
