import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Form, Row, Col, Select, Radio, DatePicker, InputNumber, Button, Table, Tooltip } from 'antd';
import { Moment, isMoment } from 'moment-timezone';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { ColumnProps } from 'antd/lib/table';
import { useAppStore } from '../../stores';
import { useTableScrollHeight } from '../../../custom_shared/hooks';
import { Utils } from '../../misc';
import { BusinessDto } from '../../types';
import './BusinessSearchModal.less';

type FormValues = {
    BusinessPartner?: string[];
    BusinessPartnerOperator?: string;
    InsuredPeriodStart?: Moment;
    InsuredPeriodEnd?: Moment;
    UnderwritingYear?: number
};

interface Props {
    onBusinessClick?: (business: BusinessDto) => void
}

const BusinessSearchModal: React.FC<Props> = ({ onBusinessClick }: Props) => {
    const appStore = useAppStore();

    const { tab, businessSearchStore } = appStore;

    const [form] = Form.useForm<FormValues>();

    const { scrollHeight } = useTableScrollHeight(700);

    const onClose = () => {
        businessSearchStore.setModalVisible(false);
    };

    const onSubmit = () => {
        const insuredPeriodStart = form.getFieldValue('InsuredPeriodStart');
        const insuredPeriodEnd = form.getFieldValue('InsuredPeriodEnd');

        const filter = {
            ...form.getFieldsValue(),
            InsuredPeriodStart: isMoment(insuredPeriodStart) ? insuredPeriodStart.utc().startOf('day').toISOString() : undefined,
            InsuredPeriodEnd: isMoment(insuredPeriodEnd) ? insuredPeriodEnd.utc().endOf('day').toISOString() : undefined
        };

        businessSearchStore.updatePaging(0, businessSearchStore.pageSize);
        businessSearchStore.getBusinesses(Utils.filterEmptyEntries(filter));
    };

    const onRow = (business: BusinessDto) => {
        return {
            onClick: () => (onBusinessClick ? onBusinessClick(business) : undefined)
        };
    };

    const renderCell = (value: string | number) => <div className="table-cell-content">{value}</div>;

    const columns: ColumnProps<unknown>[] = [
        {
            title: 'Business ID',
            dataIndex: 'Identifier',
            key: 'Identifier',
            render: (value: string) => renderCell(value),
            onCell: () => ({
                style: { minWidth: 80 }
            })
        },
        {
            title: 'Business Title',
            dataIndex: 'Title',
            key: 'Title',
            render: (value: string) => <Tooltip title={value}>{renderCell(value)}</Tooltip>,
            onCell: () => ({
                style: { maxWidth: 250 }
            })
        },
        {
            title: 'Underwriting Year',
            dataIndex: 'UnderwritingYear',
            key: 'UnderwritingYear',
            render: (value: number) => renderCell(value),
            onCell: () => ({
                style: { minWidth: 100 }
            })
        },
        {
            title: 'Cover',
            dataIndex: 'Cover',
            key: 'Cover',
            render: (value: number) => renderCell(Utils.formatAmount(value)),
            onCell: () => ({
                style: { minWidth: 80 }
            })
        },
        {
            title: 'Excess',
            dataIndex: 'Excess',
            key: 'Excess',
            render: (value: number) => renderCell(Utils.formatAmount(value)),
            onCell: () => ({
                style: { minWidth: 80 }
            })
        },
        {
            title: 'Liability',
            dataIndex: 'Liability',
            key: 'Liabilityr',
            render: (value: number) => renderCell(Utils.formatAmount(value)),
            onCell: () => ({
                style: { minWidth: 80 }
            })
        }
    ];

    const pagination: TablePaginationConfig = {
        current: businessSearchStore.page + 1,
        pageSize: businessSearchStore.pageSize,
        hideOnSinglePage: true,
        pageSizeOptions: ['10', '25', '50'],
        onChange: (page, pageSize) => {
            businessSearchStore.updatePaging(page - 1, pageSize ?? 10);
        }
    };

    return (
        <Modal
            className="contract-ingestion-business-search-modal"
            title="Business Search"
            visible={businessSearchStore.modalVisible}
            maskClosable={false}
            width={1000}
            footer={null}
            destroyOnClose
            forceRender
            centered
            onCancel={onClose}
        >
            <Form id={`contract-ingestion-business-search-${tab.id}`} layout="vertical" form={form} preserve={false}>
                <Row gutter={20}>
                    <Col span={12}>
                        <Form.Item name="BusinessPartner" label="Business Partner">
                            <Select
                                showSearch
                                allowClear
                                mode="multiple"
                                options={businessSearchStore.businessPartnersOptions}
                                loading={businessSearchStore.businessPartnersLoading}
                                filterOption={(value, option) =>
                                    option?.title && option.title.toLowerCase().includes(value.toLowerCase())
                                }
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item name="BusinessPartnerOperator" label="Partner Filtering Operator" initialValue="AND">
                            <Radio.Group
                                options={[
                                    { label: 'And', value: 'AND' },
                                    { label: 'Or', value: 'OR' }
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={20}>
                    <Col span={12}>
                        <Form.Item name="InsuredPeriodStart" label="Insured Period Start Date">
                            <DatePicker placeholder="Select date" />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item name="InsuredPeriodEnd" label="Insured Period End Date">
                            <DatePicker placeholder="Select date" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={20}>
                    <Col span={12}>
                        <Form.Item name="UnderwritingYear" label="Underwriting Year">
                            <InputNumber />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={20}>
                    <Col className="find-now-column" span={24}>
                        <Button type="primary" onClick={onSubmit} disabled={businessSearchStore.businessesLoading}>
                            Find Now
                        </Button>
                    </Col>
                </Row>
            </Form>

            <Table
                className="business-search-table"
                dataSource={businessSearchStore.businesses}
                loading={businessSearchStore.businessesLoading}
                columns={columns}
                scroll={{ y: scrollHeight }}
                tableLayout="auto"
                rowKey={(business: BusinessDto) => businessSearchStore.businesses.indexOf(business).toString()}
                onRow={onRow}
                pagination={pagination}
            />
        </Modal>
    );
};

export default observer(BusinessSearchModal);
