import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { CustomSelectMultipleWrapper, InputActions, CustomFormItem } from '../../components';
import { InputModelWithSource } from '../../models';
import { InputWithMeta } from '../../componentTypes';

// Please note that this is a generic component if behavior for your input is different - create a new independent component (like BusinessTypeInput.tsx etc.)

interface Props {
    label: string;
    input: InputModelWithSource;
    allowClear?: boolean;
    message?: string;
    onChange?: () => void
}

const GenericSelectMultiple: InputWithMeta<Props> = ({ label, input, allowClear = true, message, onChange }) => {
    return (
        <CustomFormItem name={input.guid} required={input.required} message={message}>
            <CustomSelectMultipleWrapper
                input={input}
                label={label}
                options={input.sourceProvider.options}
                topRightNode={<InputActions input={input} />}
                allowClear={allowClear}
                required={input.required}
                onChange={onChange}
            />
        </CustomFormItem>
    );
};

GenericSelectMultiple.meta = {
    inputType: InputModelWithSource.name
};

export default observer(GenericSelectMultiple);
