import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { CustomDatePickerWrapper, InputActions, CustomFormItem } from '../../components';
import { InputModel } from '../../models';
import { InputWithMeta } from '../../componentTypes';

// Please note that this is a generic component if behavior for your input is different - create a new independent component (like BusinessTypeInput.tsx etc.)

interface Props {
    label: string;
    input: InputModel;
    message?: string
}

const GenericDatePicker: InputWithMeta<Props> = ({ label, input, message }) => {
    return (
        <CustomFormItem name={input.guid} required={input.required} message={message}>
            <CustomDatePickerWrapper
                input={input}
                label={label}
                topRightNode={<InputActions input={input} />}
                required={input.required}
            />
        </CustomFormItem>
    );
};

GenericDatePicker.meta = {
    inputType: InputModel.name
};

export default observer(GenericDatePicker);
